// eslint-disable-next-line import/no-webpack-loader-syntax
import 'expose-loader?$!jquery';
import 'expose-loader?fastorder!./newfastorder.js';

import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';

/* import { register } from 'register-service-worker';

register('/catalog/view/javascript/sw.js', {
  ready() {
    console.log('Service worker is active.');
  },
  registered(registration) {
    console.log('Service worker has been registered.');
  },
  cached(registration) {
    console.log('Content has been cached for offline use.');
  },
  updatefound(registration) {
    console.log('New content is downloading.');
  },
  updated(registration) {
    console.log('New content is available; please refresh.');
  },
  offline() {
    console.log('No internet connection found. App is running in offline mode.');
  },
  error(error) {
    console.error('Error during service worker registration:', error);
  },
});
 */

/* if ('serviceWorker' in navigator) {
	navigator.serviceWorker.register('/sw.js', { scope: '/' }).then(function(reg) {
	  // регистрация сработала
	  console.log('Registration succeeded. Scope is ' + reg.scope);
	}).catch(function(error) {
	  // регистрация прошла неудачно
	  console.log('Registration failed with ' + error);
	});
  };  */