import {getURLVar} from './common_default.js';

/**
 * Add two numbers.
 * @param {number} owl The first number.
 */
function wpo_play_owl_carousel(owl) {
	const $ = jQuery;
	const config = {
		/* 	loop: false,
			items: 1,
			nav:false,
			pullDrag: false */
		navigation: false, // Show next and prev buttons
		slideSpeed: 300,
		paginationSpeed: 400,
		pagination: $(owl).data('pagination'),
	};

	if ($(owl).data('show') === 1) {
		config.singleItem = true;
	} else {
		config.items = $(owl).data('show');
	}

	$(owl).owlCarousel(config);

	$('.left', $(owl).parent()).click(() => {
		owl.trigger('owl.prev');
		return false;
	});

	$('.right', $(owl).parent()).click(() => {
		owl.trigger('owl.next');
		return false;
	});
}

// 1. Jquery Framework ( not edit )
$(document).ready(() => {
	$('.dropdown-toggle-overlay').click(function () {
		$($(this).data('target')).addClass('active');
		$('.row-offcanvas').addClass('blur');
		$('#search-input').focus();
	});

	$('.toggle-overlay-container').click(function (e) {
		if (e.target.id !== 'search-container') return false;

		$('.toggle-overlay-container').removeClass('active');
		$('.row-offcanvas').removeClass('blur');
		return false;
	});

	// Fix First Click Menu
	$(document.body).on('click', '.megamenu [data-toggle="dropdown"], .verticalmenu [data-toggle="dropdown"]', function () {
		if (!$(this).parent().hasClass('open') && this.href && this.href != '#') {
			window.location.href = this.href;
		}
	});

	// Automatic apply  OWL carousel
	$('.owl-carousel-play .owl-carousel').each(function () {
		const owl = $(this);
		wpo_play_owl_carousel(owl);
	});

	// Adding the clear Fix
	const cols1 = $('#column-right, #column-left').length;

	if (cols1 == 2) {
		$('#content .product-layout:nth-child(2n+2)').after('<div class="clearfix visible-md visible-sm"></div>');
	} else if (cols1 == 1) {
		$('#content .product-layout:nth-child(3n+3)').after('<div class="clearfix visible-lg"></div>');
	} else {
		$('#content .product-layout:nth-child(4n+4)').after('<div class="clearfix"></div>');
	}

	// Offcanvas Click
	$('[data-toggle="offcanvas"]').click(function () {
		$('.row-offcanvas').toggleClass('active');
	});

	$('#offcanvasmenu a.dropdown-toggle').parent().append('<i class="click-canavs-menu fa fa-angle-up"></i>');
	$('i.click-canavs-menu.fa-angle-up').parent().find('.dropdown-menu:first').toggle();

	$('body').on('click', 'i.click-canavs-menu.fa-angle-up', function () {
		$(this).removeClass('fa-angle-up').addClass('fa-angle-down');
		$(this).parent().find('.dropdown-menu:first').toggle();
	});

	$('body').on('click', 'i.click-canavs-menu.fa-angle-down', function () {
		$(this).removeClass('fa-angle-down').addClass('fa-angle-up');
		$(this).parent().find('.dropdown-menu:first').toggle();
	});

	// Offcanvas Search
	/*  $('#offcanvas-search input[name=\'search\']').parent().find('button').on('click', function() {
	   url = $('base').attr('href') + 'index.php?route=product/search';
	   const value = $('.sidebar-offcanvas input[name=\'search\']').val();
	   if (value) {
	     url += '&search=' + encodeURIComponent(value);
	   }
	   location = url;
	 }); */

	// offcanvase search trigger click
	/*   $('#offcanvas-search input[name=\'search\']').on('keydown', function(e) {
	    if (e.keyCode == 13) {
	      $('.sidebar-offcanvas input[name=\'search\']').parent().find('button').trigger('click');
	    }
	  }); */

	// magnificPopup Product Zoom
	$('.product-zoom').magnificPopup({
		type: 'image',
		closeOnContentClick: true,
		image: {
			verticalFit: true
		},
	});

	$('.iframe-link').magnificPopup({
		type: 'iframe',
	});

	// magnificPopup Form Contact, etc...
	$('.popup-with-form').magnificPopup({
		type: 'inline',
		preloader: false,
		focus: '#input-name',
		callbacks: {
			beforeOpen: function () {
				if ($(window).width() < 700) {
					this.st.focus = false;
				} else {
					this.st.focus = '#input-name';
				}
			},
		},
	});

	// scroll-to-top button show and hide
		jQuery(window).scroll(function () {
			if (jQuery(this).scrollTop() > 100) {
				jQuery('.scrollup').fadeIn();
			} else {
				jQuery('.scrollup').fadeOut();
			}
		});
		// Scroll-to-top animate
		jQuery('.scrollup').click(function () {
			jQuery('html, body').animate({
				scrollTop: 0
			}, 600);
			return false;
		});

	$('.dropdown-menu input').click(function (e) {
		e.stopPropagation();
	});

	// Grid list switcher at category page

	$('button.btn-switch').bind('click', function (e) {
		e.preventDefault();
		const theid = $(this).attr('id');
		const row = $('#products');


		if ($(this).hasClass('active')) {
			return false;
		} else {
			if (theid == 'list-view') {
				$('#list-view').addClass('active');
				$('#grid-view').removeClass('active');
				// remove class list
				row.removeClass('product-grid');
				// add class gird
				row.addClass('product-list');
				localStorage.setItem('display', 'list');
			} else if (theid == 'grid-view') {
				$('#grid-view').addClass('active');
				$('#list-view').removeClass('active');
				// remove class list
				row.removeClass('product-list');
				// add class gird
				row.addClass('product-grid');
				localStorage.setItem('display', 'grid');
			}
		}
	});

	if (localStorage.getItem('display') == 'list') {
		$('#list-view').trigger('click');
	} else {
		$('#grid-view').trigger('click');
	}



	// Quantity Adder at Product page
	$('.quantity-adder .add-action').click(function () {
		if ($(this).hasClass('add-up')) {
			$('[name=quantity]', '.quantity-adder').val(parseInt($('[name=quantity]', '.quantity-adder').val()) + 1);
		} else {
			if (parseInt($('[name=quantity]', '.quantity-adder').val()) > 1) {
				$('input', '.quantity-adder').val(parseInt($('[name=quantity]', '.quantity-adder').val()) - 1);
			}
		}
	});
}); // end document


// 2. Customize other function script
$(document).ready(() => {
	// search
	$('#search input[name=\'search\']').parent().find('button').on('click', function () {
		let url = '/search';
		const value = $('#search input[name=\'search\']').val();
		if (value) {
			url += '?search=' + encodeURIComponent(value);
		}

		//YANDEX METRICA O-DOG
		if(typeof yaCounter36573010 !== "undefined") {
			yaCounter36573010.reachGoal('ID-4');
		}

		location = url;
	});


	$('#search input[name=\'search\']').on('keydown', function (e) {
		if (e.keyCode == 13) {
			$('#search input[name=\'search\']').parent().find('button').trigger('click');
		}
	});
}); // end document

// 3. addtocart, wishlish, compare
global.cart = {
	add: function (product_id, quantity) {
		$.ajax({
			url: 'index.php?route=checkout/cart/add',
			type: 'post',
			data: `product_id=${  product_id  }&quantity=${  typeof(quantity) != 'undefined' ? quantity : 1}`,
			dataType: 'json',
			beforeSend() {
				$('#cart > button').button('loading');
			},
			complete() {
				$('#cart > button').button('reset');
			},
			success(json) {
				$('.alert, .text-danger').remove();

				if (json['redirect']) {
					location = json['redirect'];
				}

				if (json['success']) {
					//$('#notification').html('<div class="alert alert-success"><i class="fa fa-check-circle"></i> ' + json['success'] + ' <button type="button" class="close" data-dismiss="alert">&times;</button></div>');
			        const res = json['total'].split(" "); 
					$('#text-items').html(res[1]);
					const out = json['total'].substr(0,json['total'].length);
					const text = json['total'].split(" ");
					$('#cart-total').html(text[1]);
					$('#cart-text').html(out);
					$('html, body').animate({ scrollTop: 0 }, 'slow');
	
					let html = '<div class="modal modal-cart" id="modalAddedToCart" tabindex="-1" role="dialog" aria-labelledby="modalAddedToCartTitle" aria-hidden="true">';
					html +='	<div class="modal-dialog modal-dialog-centered" role="document">';
					html +='		<div class="modal-content">';
					html +='			<div class="modal-header">';
					html +='				<h5 class="modal-title" id="modalAddedToCartTitle">Товар добавлен в корзину</h5>';
					html +='				<span class="modal-description">Всего в вашей корзине ' + text[1] + ' товар(-а)</span>';
					html +='				<button type="button" class="close" data-dismiss="modal" aria-label="Close">';
					html +='				<span aria-hidden="true">&times;</span>';
					html +='				</button>';
					html +='			</div>';
					html +='			<div class="modal-body">';
					html +='				<div class="modal-body-left">';
					html +='					<div class="image">';
					html +='						<img class="ima-fluid" src="/image/catalog/error_background.jpg" alt="Мопс">';
					html +='					</div>';
					html +='				</div>';
					html +='				<div class="modal-body-right">';
					html +='					<p class="modal-promo-text">Используйте промокод c <span class="percents">3%</span> скидкой на первый заказ</p>';
					html +='					<div class="modal-promo-code-bkg">';
					html +='					<span class="modal-promo-code">SWEETDOG</span>';
					html +='				</div>';
					html +='				<div class="modal-buttons">';
					html +='					<a href="/" class="continue">Продолжить покупки</a>';
					html +='					<form action="/cart">';
					html +='						<button type="submit" onclick="yaCounter36573010.reachGoal("ID-9"); return true;" class="btn btn-primary">Оформить заказ</button>';
					html +='					</form>';
					html +='				</div>';
					html +='			</div>';
					html +='		</div>';
					html +='	</div>';
					html +='</div>';

					$('body').append(html);

					$('#modalAddedToCart').modal('show');

					//YANDEX METRICA O-DOG
					if(typeof yaCounter36573010 !== "undefined") {
						yaCounter36573010.reachGoal('ID-5');
					}

					$('#cart > ul').load('index.php?route=common/cart/info ul li');
				}
			},
			error(xhr, ajaxOptions, thrownError) {
				alert(thrownError + '\r\n' + xhr.statusText + '\r\n' + xhr.responseText);
			},
		});
	},
	remove: function (key) {
		$.ajax({
			url: 'index.php?route=checkout/cart/remove',
			type: 'post',
			data: `key=${  key}`,
			dataType: 'json',
			beforeSend() {
				$('#cart > button').button('loading');
			},
			complete() {
				$('#cart > button').button('reset');
			},
			success(json) {
				// Pavo 2.2 edit
				if ($('#cart #cart-total').hasClass('cart-mini-info')) {
					json['total'] = json['total'].replace(/-(.*)+$/, '');
				}

				const out = json['total'].substr(0, json['total'].length);
				let text = json['total'].split(' ');
				$('#cart-total').html(text[1]);
				$('#cart-text').html(out);
				// Pavo 2.2 end edit

				if (getURLVar('route') == 'checkout/cart' || getURLVar('route') == 'checkout/checkout') {
					location = 'index.php?route=checkout/cart';
				} else {
					$('#cart > ul').load('index.php?route=common/cart/info ul li');
				}
			},
			error(xhr, ajaxOptions, thrownError) {
				alert(thrownError + '\r\n' + xhr.statusText + '\r\n' + xhr.responseText);
			},
		});
	},
};

global.wishlist = {
	add: function (product_id) {
		$.ajax({
			url: 'index.php?route=account/wishlist/add',
			type: 'post',
			data: `product_id=${  product_id}`,
			dataType: 'json',
			success(json) {
				$('.alert').remove();

				if (json['redirect']) {
					location = json['redirect'];
				}

				if (json['success']) {
					$('#notification').html('<div class="alert alert-success"><i class="fa fa-check-circle"></i> ' + json['success'] + ' <button type="button" class="close" data-dismiss="alert">&times;</button></div>');
				}

				$('#wishlist-total span').html(json['total']);
				$('#wishlist-total').attr('title', json['total']);

				$('#wishlist-total', window.parent.document).html(json['total']);

				$('html, body').animate({
					scrollTop: 0
				}, 'slow');
			},
			error(xhr, ajaxOptions, thrownError) {
				alert(thrownError + '\r\n' + xhr.statusText + '\r\n' + xhr.responseText);
			},
		});
	},
	remove: function () {

	},
};

global.compare = {
	add: function (product_id) {
		$.ajax({
			url: 'index.php?route=product/compare/add',
			type: 'post',
			data: `product_id=${  product_id}`,
			dataType: 'json',
			success(json) {
				$('.alert').remove();

				if (json['success']) {
					$('#notification').html('<div class="alert alert-success"><i class="fa fa-check-circle"></i> ' + json['success'] + ' <button type="button" class="close" data-dismiss="alert">&times;</button></div>');

					$('#compare-total').html(json['total']);

					$('#compare-total', window.parent.document).html(json['total']);

					$('html, body').animate({
						scrollTop: 0
					}, 'slow');
				}
			},
			error(xhr, ajaxOptions, thrownError) {
				alert(thrownError + '\r\n' + xhr.statusText + '\r\n' + xhr.responseText);
			},
		});
	},
	remove: function () {

	},
};