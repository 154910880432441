/**
 * Add two numbers.
 * @param {number} key The first number.
 * @return {number} The sum of the two numbers.
 */
export function getURLVar(key) {
  const value = [];

  const query = String(document.location).split('?');

  if (query[1]) {
    const part = query[1].split('&');

    for (let i = 0; i < part.length; i++) {
      const data = part[i].split('=');

      if (data[0] && data[1]) {
        value[data[0]] = data[1];
      }
    }

    if (value[key]) {
      return value[key];
    } 
      return '';
    
  }
}

$(document).ready(() => {
  const route = getURLVar('route');

  if (!route) {
    $('#menu #home').addClass('active');
  } else {
    const part = route.split('/');

    let url = part[0];

    if (part[1]) {
      url += '/' + part[1];
    }

    $('#menu a[href*=\'index.php?route=' + url + '\']').parents('li[id]').addClass('active');
  }
});
